/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/bf1e84cd9e3233b9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c4dcff03287abec3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_ad2549';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_ad2549';src: local("Arial");ascent-override: 94.32%;descent-override: 24.82%;line-gap-override: 0.00%;size-adjust: 100.72%
}.__className_ad2549 {font-family: '__Figtree_ad2549', '__Figtree_Fallback_ad2549'
}.__variable_ad2549 {--font-figtree: '__Figtree_ad2549', '__Figtree_Fallback_ad2549'
}

/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/89b26bfa1d4db342-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2f9854c3cd6cadbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/68c7b31e1cb67762-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2a4fef81f8fe7ac1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/8eb797bc58c123a0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2b06160cef652d7e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/89b26bfa1d4db342-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2f9854c3cd6cadbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/68c7b31e1cb67762-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2a4fef81f8fe7ac1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/8eb797bc58c123a0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2b06160cef652d7e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/89b26bfa1d4db342-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2f9854c3cd6cadbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/68c7b31e1cb67762-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2a4fef81f8fe7ac1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/8eb797bc58c123a0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2b06160cef652d7e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/89b26bfa1d4db342-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2f9854c3cd6cadbf-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/68c7b31e1cb67762-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2a4fef81f8fe7ac1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/8eb797bc58c123a0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/2b06160cef652d7e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c91ecbf34bf43ecd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/7d18accce7f2570d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/eedc9723bb02f3db-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/251c031c71a0c212-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/21f312cc4db4339c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/df08ff1da7eabf18-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c91ecbf34bf43ecd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/7d18accce7f2570d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/eedc9723bb02f3db-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/251c031c71a0c212-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/21f312cc4db4339c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/df08ff1da7eabf18-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c91ecbf34bf43ecd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/7d18accce7f2570d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/eedc9723bb02f3db-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/251c031c71a0c212-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/21f312cc4db4339c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/df08ff1da7eabf18-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/c91ecbf34bf43ecd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/7d18accce7f2570d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/eedc9723bb02f3db-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/251c031c71a0c212-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/21f312cc4db4339c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__STIX_Two_Text_f0a9a0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/prod/3.100.3/_next/static/media/df08ff1da7eabf18-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__STIX_Two_Text_Fallback_f0a9a0';src: local("Times New Roman");ascent-override: 72.67%;descent-override: 22.70%;line-gap-override: 23.84%;size-adjust: 104.86%
}.__className_f0a9a0 {font-family: '__STIX_Two_Text_f0a9a0', '__STIX_Two_Text_Fallback_f0a9a0'
}.__variable_f0a9a0 {--font-stix: '__STIX_Two_Text_f0a9a0', '__STIX_Two_Text_Fallback_f0a9a0'
}

